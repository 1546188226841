
import { defineComponent, onMounted, reactive, watch } from "vue";
import { required, validEmailOrCpf, validCpf, testCpf } from "@/utils/validationFunctions";
import { useRouter } from "vue-router";

import Form from "@/components/Form.vue";
import LoginInput from "@/components/LoginInput.vue";
import vm from "@/viewModels/MainViewModel"
import ExameService from "@/services/ExameService";
import SelectStates from "@/components/SelectStates.vue";
import { trackers } from "@/hooks/insights";
import GestorService from "@/services/GestorService";
import { CreateExamDTO } from "@/dtos/CreateExamDTO";
import { SchedulingDemoDTO } from "@/dtos/SchedulingDemoDTO";



interface LoginFormViewState {
    cpf: string;
    email: string;
    name: string;
    password: string;
    loading: boolean;
    error: any;
    selectUfModal: boolean;
    errorUf: boolean;
}

const DemoRegisterView = defineComponent({
    components: { Form, LoginInput, SelectStates },
    setup() {
        
        // Hooks
        const { replace } = useRouter();
        //demo cannot be used in production
        process.env.NODE_ENV === 'production' ? replace("/login") : ""
        const userGestor = process.env.VUE_APP_LOGIN_GESTOR_USERNAME;
        const passwordGestor = atob(process.env.VUE_APP_LOGIN_GESTOR_PASSWORD);

        // State
        const state = reactive<LoginFormViewState>({
            cpf: "",
            email: "",
            name: "",
            password: "",
            loading: false,
            error: "",
            selectUfModal: false,
            errorUf: false
        });

        onMounted(() => {
            vm.federativeUnitSelected = ""
        });

        watch(
            () => state.cpf,
            (newValue, oldValue) => {
                const isCpf = /\d{11}/.test(newValue);
                if (isCpf && newValue.length == 11) {
                    const cpf =
                        newValue.substring(0, 3) +
                        "." +
                        newValue.substring(3, 6) +
                        "." +
                        newValue.substring(6, 9) +
                        "-" +
                        newValue.substring(9, 11);
                    state.cpf = cpf;
                }
                if (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(oldValue)) {
                    const value = newValue.replace(new RegExp("[.-]", "gi"), "");
                    state.cpf = value;
                }
            }
        );

        const generateGestorExam = async (scheduling: SchedulingDemoDTO) => {
            GestorService.loginGestor(userGestor, passwordGestor)
                .then(() => {
                    const cpf = state.cpf.replaceAll(".", "").replaceAll("-", "");
                    const renach = scheduling.schedulingIntegrationId.slice(0, 10);
                    const createExam: CreateExamDTO = {
                        "candidateCpf": cpf,
                        "candidateName": state.name,
                        "schedulingDate": scheduling.schedulingDate.toString(),
                        "schedulingId": scheduling.schedulingIntegrationId,
                        "companyCnpj": scheduling.companyCNPJ,
                        "type": "5",
                        "renach": renach,
                        "schedulingLocal": "1",
                        "schedulingLocalDescription": "CFC AUTO ESCOLA CUPIRA LTDA",
                        "schedulingTurn": 1,
                        "examinadorCpf": "",
                        "processStartDate": new Date()
                    }
                    GestorService.createExamGestor(createExam);
                });
        }

        const submit = async (data: { isValid: boolean; errors: string[]; }) => {
            state.error = "";

            if (!data.isValid) {
                if (vm.federativeUnitSelected == "") {
                    state.errorUf = true;

                } else {
                    state.errorUf = false;
                }

                state.error = data.errors[0];
                return;
            } else {
                if (vm.federativeUnitSelected == "") {
                    state.errorUf = true;
                    throw state.error = "Estado inválido";
                } else {
                    state.errorUf = false;
                }

                try {
                    state.loading = true;

                    const requestSchedulingDemo = await ExameService.GetTesteExameTeorico()[0];
                    const responseSchedulingDemo = await ExameService.CreateScheduling({
                        ...requestSchedulingDemo,
                        candidateCpf: state.cpf.replaceAll(".", "").replaceAll("-", ""),
                        candidateName: state.name,
                        federativeUnit: vm.federativeUnitSelected
                    })[0];

                    await generateGestorExam(requestSchedulingDemo);

                    if (responseSchedulingDemo) {
                        replace({ name: "demo-biometry", params: { cpf: state.cpf } });
                        trackers.registerPageView('DemoRegister', `/demo-biometry/${state.cpf}`)
                    }


                } catch (error: any) {
                    trackers.registerError('Erro', error.toString())
                    state.error = error;
                    state.loading = false;
                }
            }
        };

        return { state, submit, required, validEmailOrCpf, validCpf };
    },
});

export default DemoRegisterView;
