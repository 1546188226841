import { GetGestorSession, PostGestor } from "./BaseService";
import $httpGestor from '../plugins/httpGestor';
import { deviceId } from "@/utils/stringFunctions";
import { CreateExamDTO } from "@/dtos/CreateExamDTO";

interface RequestLoginDTO {
    deviceId: string | null;
    username: string;
    password: string;
}

export default {
    refreshFirebaseToken: () => {
        const session = GetGestorSession<any>(`refresh_firebase_token`);
        return session;
    },

    loginGestor: async (username: string, password: string): Promise<any> => {

        const session = await PostGestor<RequestLoginDTO, any>(
            'auth_users_login',
            { deviceId: null, username: username, password: password }
        )[0];
        configSession(session);
        return session;
    },

    createExamGestor: async (createExam: CreateExamDTO) => {
        const exam = await PostGestor<CreateExamDTO, any>('app_exam_exams_create', { ...createExam })[0];
        return exam;
    }
};

const configSession = (session: any): void => {
    $httpGestor.defaults.headers.common['Authorization'] = "Bearer " + session.token;
    localStorage.setItem('sessionGestor', JSON.stringify(session));
};